




















import FlsActivityForm from '@/components/flsActivity/FlsActivityForm.vue';
import FlsActivityUtils, { FlsActivity } from '@/utils/FlsActivityUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { FlsActivityForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      errors?: any[];
    };
    item?: Partial<FlsActivity>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async mounted() {
    this.item = FlsActivityUtils.flsActivity.generateDefault();
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();

          this.item = FlsActivityUtils.flsActivity.generateDefault(
            await FlsActivityUtils.api.create(this.item)
          );

          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.create', [this.$tc('fls_activity.label')]),
            type: 'success',
          });
          this.$router.replace({ name: 'fls-activity-list' });
        } catch (error: any) {
          this.$toast.push({
            text: this.$t('error.create', [this.$tc('fls_activity.label')]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },
  },
});
